import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, Row, Col, Badge} from 'reactstrap'

import styled from "styled-components"

import Layout from "../components/layout"
import MastHead from "../components/masthead"
import { SiteHeading, H1, SubHeading } from "../components/site-heading"
import SEO from "../components/seo"

const Year = styled(Col)`  
  font-size: 4rem;

  @media screen and (min-width: 0px) {
    
  }

  @media screen and (min-width: 900px) {
    writing-mode: vertical-rl;
  }

  @media screen and (min-width: 1100px) {
    writing-mode: vertical-rl;
  }

  @media screen and (min-width: 1400px) {
    writing-mode: vertical-rl;
    font-size: 8rem;
  }
`

const colours = {
  0: "primary",
  1: "secondary",
  2: "success",
  3: "danger",
  4: "warning",
  5: "info",
  6: "light",
  7: "dark"
}

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query BlogQuery {
        allMdx(limit: 1000, filter: {fields: {slug: {regex: "^/blog/"}}}, sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                id
                fields {
                  slug
                }
                frontmatter {
                    title
                    date(formatString: "MMM DD, YYYY")        
                    year:date(formatString:"YYYY")
                    tags
                }
            }
        }
        banner: file(relativePath: {eq: "images/banner.jpg"}, sourceInstanceName: {eq: "blog"}) {
          childImageSharp {
            fluid(quality:100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `)  

  const articles = data.allMdx.nodes
  const banner = data.banner.childImageSharp.fluid

  let array = []
  let year = null 
  let inner = []
  let tags = {}

  for(let article of articles) {
      if(year !== article.frontmatter.year) {
        year = article.frontmatter.year
        inner = [article]
        array.push(inner)
      } else {
          inner.push(article)
      }
      if(article.frontmatter.tags && article.frontmatter.tags.length > 0) {
        for(let tag of article.frontmatter.tags) {
          tags[tag] = tags[tag] || 0
          tags[tag]++
          if(tag == null){
            console.log(tag, article.frontmatter)
          }
          
        }
      }      
  }

  let i = 0;
  const max = Object.keys(colours).length
  for(let key in tags) {
    tags[key] = 
      {
        count: tags[key],
        colour: colours[(i%max)]
      }
    i++
  }

  return (
    <Layout>      
      <SEO title="Blog" />
      <MastHead imageData={banner}>
        <SiteHeading>
          <H1>Blog</H1>
          <SubHeading>Learnings &amp; Reflections</SubHeading>
        </SiteHeading>          
      </MastHead> 
      <Container> 
      <Row>
        <Col className="pt-3">
          {tags &&
            Object.keys(tags).map((key,j) => {        
              const colour = tags[key].colour
              // const count = tags[key].count
              return (
                <Badge className="mx-1" pill key={j} color={colour}>{key}</Badge>
              )
            })
          }
        </Col>
      </Row>                 
      { 
        array.map((list, index) => {        
          return (
            <Row key={index} className="pt-5 align-items-center">
                <Year md="2" lg="1">{list[0].frontmatter.year}</Year>
                <Col md="9" lg="11">
                    {                    
                        list.map((article, i) => {
                            return (
                                <div key={i}>
                                    <Link to={article.fields.slug}><strong>{article.frontmatter.title}</strong></Link>
                                    {article.frontmatter.tags &&
                                      article.frontmatter.tags.map((tag,j) =>{
                                        const colour = tags[tag].colour
                                        return (
                                          <Badge key={j} className="mx-1" color={colour} pill>{tag}</Badge>
                                        )
                                      })                                    
                                    }
                                    <span></span>
                                </div>
                            )
                        })                    
                    }     
                </Col>        
            </Row>
          )
        })
      } 
      </Container>   
    </Layout>
  )
}

export default BlogPage
